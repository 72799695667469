@import '../abstracts/_mixins';
@import '../abstracts/_variables';
@import '../components/title';
@import '../components/button';
@import '../components/input';

/* ************GENERIQUE STYLE ************ */
.modal {
    padding: 0 !important;

    .modal-content {
        border-bottom: 3px solid var(--toc-orange);

        .modal-header {
            border: none;

            & .modal-title {
                @include h1();

                //override mixin
                &::after {
                    width: 80%;
                }
            }

            button[aria-label="Close"] {
                display: none;
            }
        }

        .modal-header {
            border: none;
        }

        .modal-body {
            .spinner-border {
                width: 4rem;
                height: 4rem;
                border: 0.25em solid var(--toc-orange);
                border-right-color: transparent;
            }
        }

        .modal-footer {
            border: none;

            button {
                color: var(--toc-brown);
                box-shadow: none;
                font-size: 1.3rem;
                font-weight: bold;

                &:hover {
                    color: var(--toc-orange);
                    text-decoration: underline;
                }
            }
        }
    }
}


/* ************SPECIFIQUE STYLE FOR EACH MODAL ************ */
#modalStore {

    //OVERRIDE BOOTSTRAP
    @media (min-width: 576px) {
        .modal-xl {
            max-width: 100vw;
        }
    }

    @media (min-width: 992px) {
        .modal-xl {
            max-width: 992px;
        }
    }

    @media (min-width: 1200px) {
        .modal-xl {
            max-width: 1620px;
        }
    }

    .modal-body {
        //OVERRIDE BOOTSTRAP

        .modal-boutiques {
            position: relative;
            height: 70vh;

            .stores {
                position: absolute;
                top: 1rem;
                left: 1rem;
                z-index: 1;

                &__localisation {
                    position: relative;
                    margin-bottom: 1rem;
                    padding: 1rem;

                    & button {
                        @include flex-row-justify(flex-start);
                        @include buttons();
                        background-color: var(--toc-white);
                        border-radius: 5rem;
                        box-shadow: 0px 5px 10px 0px #ef7d0087;
                        color: var(--toc-dark-orange);
                        text-shadow: none;

                        span {
                            @include flex-row-justify(space-between);
                            gap: 1rem;

                            &::after {
                                @include icons($fontAwesomeFree, '\f601', 2rem, none);
                            }

                            width: 100%;
                        }

                        i {
                            font-weight: 900;
                        }
                    }

                    &__popover {
                        position: absolute;
                        width: auto !important;
                        right: 0;
                        top: 0;

                        & i {
                            color: var(--toc-black);
                            font-weight: 900;
                            font-size: 2rem;
                        }
                    }
                }

                &__accordion {
                    overflow: auto;
                    max-height: 62vh;

                    & .card {

                        &.store-infos-open {
                            border-bottom: 0.5rem solid var(--toc-orange);
                            margin-bottom: 0.5rem;
                            box-shadow: #35180c91 0px 5px 15px 0px;
                        }

                        &__header {
                            padding: 0;
                            background: var(--toc-noug);


                            &>.--action {
                                @include flex-row-justify(space-between);
                                padding: 0.5rem;
                                min-height: 3.5rem;
                                font-size: 1.2rem;
                                font-weight: bold;
                                color: var(--toc-white);

                                &:hover {
                                    text-decoration: none;
                                }

                                &[aria-expanded="true"] {
                                    background-color: var(--toc-orange);
                                }

                            }

                            &__title {
                                // color: var(--toc-white);
                            }

                            &__click-and-collect {
                                padding: 0.5rem;
                                font-size: 1rem;
                                background: var(--toc-grey);
                                color: var(--toc-brown);
                                min-width: 10.1rem;
                            }

                        }

                        &__content {
                            background-color: var(--toc-white);

                            &__store {
                                &__header {
                                    @include flex-column();
                                    row-gap: 0.5rem;
                                    font-size: 1.2rem;

                                    p {
                                        margin: 0;
                                        display: flex;
                                        align-items: center;
                                        gap: 1rem;
                                        color: var(--toc-brown);
                                        font-size: 1.4rem;

                                        span {
                                            i {
                                                font-size: 2.5rem;
                                                color: var(--toc-orange);
                                            }
                                        }

                                        a {
                                            color: var(--toc-brown);
                                        }
                                    }
                                }

                                &__body {
                                    color: var(--toc-brown);

                                    tr {
                                        &:nth-child(1) {
                                            td {
                                                border-top: inherit;
                                            }
                                        }
                                    }

                                    .date-color {
                                        font-weight: bold;
                                        color: var(--toc-orange)
                                    }

                                    &__schedule {
                                        i {
                                            position: relative;
                                            top: 4px;
                                            font-size: 2.5rem;
                                            color: var(--toc-orange);
                                        }
                                    }
                                }

                                &__footer {
                                    &__select--button {
                                        @include button-cta(primary);
                                    }

                                    &__deselect--button {
                                        @include button-cta(tertiary);
                                    }

                                    &--button-cms-page {
                                        &__available {
                                            @include buttons();
                                            margin-top: 0.5rem;
                                            font-size: 1.4rem;
                                            color: var(--toc-dark-blue);
                                            text-shadow: none;

                                            &:hover {
                                                border-radius: 5rem;
                                                background-color: rgba(39, 172, 227, 0.2);
                                            }
                                        }

                                        &__unavailable {}
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .maps {
                z-index: 0;
            }
        }
    }



    @media (max-width: $breakpoint-lg) {
        & {
            .modal-body {
                .modal-boutiques {
                    height: 100%;
                    row-gap: 1.5rem;

                    .stores {
                        position: relative;
                        left: 0;
                    }

                    .maps {
                        margin-top: 1rem;
                        height: 30rem;
                    }
                }
            }
        }
    }
}

.popover-click-and-collect {

    & .popover-header {
        font-size: 1.2rem;
        text-align: center;
    }

    & .popover-body {
        background-color: var(--toc-brown);
    }

    &__content {
        padding: 1rem;
        font-family: Montserrat;
        margin: 0;
        color: var(--toc-orange);
        font-size: 1.3rem;
    }

    &__lottie {
        width: auto;
    }
}

#modalWishlist {
    .wishlist-list {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        &-item {
            @include input-checkbox(checked);
        }
    }
}